.cstm_error {
    color: #f44336;
    font-weight: 600;
}

.cstm_warn {
    color: #24b6dc;
    font-weight: 600;
}
  
.cstm_completed {
    color: #4caf50;
    font-weight: 600;
}

.inputFileUpload input{
    margin-top: 15px !important;
  }